// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("@rails/activestorage").start()
require("channels")


//= require 'jquery.jplayer'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";

const FADEINTIME = 1;
document.addEventListener('DOMContentLoaded', ()=> {
	// gsap.to(['body','html'], FADEINTIME, {opacity: 1, ease: Power4.easeInOut} )
  openPopup(); 
	watchForHover();
  isSafari(); 
  scrollHint()
  showHideLogo(); 
  initThankYouForm()
  closePopup()
})

function isSafari() {
  var isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  if (isSafari) { 
    document.querySelector('body').classList.add('is-safari')
  };
}

function watchForHover() {
  // lastTouchTime is used for ignoring emulated mousemove events
  let lastTouchTime = 0

  function enableHover() {
    if (new Date() - lastTouchTime < 500) return
    document.body.classList.add('hasHover')
  }

  function disableHover() {
    document.body.classList.remove('hasHover')
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date()
  }

  document.addEventListener('touchstart', updateLastTouchTime, true)
  document.addEventListener('touchstart', disableHover, true)
  document.addEventListener('mousemove', enableHover, true)

  enableHover()
}

function initThankYouForm(){
    if(document.querySelector("#email-form")){
          document.querySelector("#email-form").addEventListener('submit', () => {
          document.querySelector("#email-form-inner").classList.add('hidden')
          document.querySelector('#thank-you-form-inner').classList.add('is-active')
      })
    }
    if(document.querySelector("#email-form-antimeta")){
          document.querySelector("#email-form-antimeta").addEventListener('submit', () => {
          document.querySelector("#email-form-inner").classList.add('hidden')
          document.querySelector('#thank-you-form-inner').classList.add('is-active')
      })
    }
}


function openPopup(){
  document.querySelectorAll('.js-open-popup').forEach(item => {
    item.addEventListener('click', () => {
      let id = item.getAttribute('data-popup')

      document.querySelector("#"+id).classList.add('is-active')
      if(id == 'email-form'){
        document.querySelector("#"+id).setAttribute('action', '/add_contact')  
        document.querySelector("#email-form-inner").classList.remove('hidden')
        document.querySelector('#thank-you-form-inner').classList.remove('is-active')
      }
      if(id == 'email-form-antimeta'){
        document.querySelector("#"+id).setAttribute('action', '/add_contact_antimeta')  
        document.querySelector("#email-form-inner").classList.remove('hidden')
        document.querySelector('#thank-you-form-inner').classList.remove('is-active')
      }
      document.querySelector('html, body').classList.add('noscroll')
      
    })
  })
}



function closePopup(){
  document.querySelectorAll('.js-close-popup').forEach(item => {
   
    item.addEventListener('click', () => {
      document.querySelectorAll('.popup').forEach(i => {i.classList.remove('is-active')})
      document.querySelector('html, body').classList.remove('noscroll')
    })
  })
}


function scrollHint(){
  const scrollHint = document.querySelector('.js-scroll-hint')
  if (scrollHint){
    scrollHint.addEventListener('click', () => {
      if (document.querySelector('.antimeta')){
          $('html, body').animate({
              scrollTop: $('.antimeta_actions').offset().top - 20
            },'slow');
      } else {
           $('html, body').animate({
              scrollTop: $('.home_images').offset().top
            },'slow');
      }
     
    })
  }
}


function showHideLogo(){
  const homeHero = document.querySelector('.home_hero')
  const headerLogo = document.querySelector('.header_logo')
  const mobileLogo = document.querySelector('.mobile-logo')
  const scrollHint = document.querySelector('.scroll-hint')
  if (homeHero){
    document.addEventListener('scroll', () => {
      if(document.documentElement.scrollTop > homeHero.clientHeight / 2){
        headerLogo.classList.add('is-active')
        mobileLogo.classList.add('is-active')
        scrollHint.classList.remove('is-active')
      } else {
        headerLogo.classList.remove('is-active')
        mobileLogo.classList.remove('is-active')
        scrollHint.classList.add('is-active')
      }
    })
  }
}